<template>
  <main>
    <!-- <ConversionData /> -->
    <router-view/>
  </main>
</template>

<script>
// import ConversionData from "@/components/Pages/ConversionData/index.vue";
export default {
  components: {
    // ConversionData,
  },
};
</script>
